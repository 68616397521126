import request from '@/utils/request'
import { iResponse } from '@/services/types'
import qs from 'qs'

export default () =>
  new (class {
    // 获取漏洞分享链接
    shareUrlVuln(params: any): Promise<iResponse> {
      return request.post(`/share_url/token/vuln/`, params)
    }

    // 获取sca分享链接
    shareUrlSca(params: any): Promise<iResponse> {
      return request.post(`/share_url/token/sca/`, params)
    }

		// 漏洞分享记录
		shareList(params: any): Promise<iResponse> {
      return request.get(`/share_url/token/?${qs.stringify(params)}`)
    }

		shareDetail(id: any): Promise<iResponse>  {
			return request.get(`/share_url/token/${id}`)
		}

		shareEdit(id: any, params: any): Promise<iResponse>  {
			return request.put(`/share_url/token/${id}`, params)
		}

		shareDelete(id: any): Promise<iResponse>  {
			return request.delete(`/share_url/token/${id}`)
		}

    ///api/v1/share_url/token/generate
		generate(): Promise<iResponse>  {
			return request.get(`/share_url/token/generate`)
		}
  })()
